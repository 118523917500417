
import { defineComponent, ref, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "style-guide",
  setup() {
    const loading = ref(true);

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageTitle("Style Guide");
    });

    return {
      loading,
    };
  },
});
